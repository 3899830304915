"use strict";
/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 47
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/bindg/Global%20Product%20Workspace/implementation/web)
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FindAlertModified = exports.FindAlertDeleted = exports.FindAlertCreated = exports.FeedbackFormViewed = exports.FeedbackFormSubmitted = exports.ErrorViewed = exports.DocumentViewed = exports.DocumentShared = exports.DocumentSaved = exports.DocumentFavorited = exports.DocumentExported = exports.DocumentCompared = exports.DocketUpdateSubmitted = exports.DocketUpdateAuthorization = exports.DocketTrackModified = exports.DocketTrackModalViewed = exports.DocketTrackDeleted = exports.DocketTrackAlertCreated = exports.DocketEntriesFiltered = exports.DirectoryViewed = exports.DashboardViewed = exports.ContractTaskPaneViewed = exports.ContentFavorited = exports.ConfirmationFormViewed = exports.ChartViewed = exports.ChartShown = exports.ChartHidden = exports.ChartFavorited = exports.ChartExported = exports.ChartDisplayModified = exports.ChartBuilderViewed = exports.CalendarEventViewed = exports.ArticleViewed = exports.ArticleShared = exports.ArticleSaved = exports.ArticleListenSkippedForward = exports.ArticleListenSkippedBack = exports.ArticleListenPlayed = exports.ArticleListenPaused = exports.ArticleListenOpened = exports.ArticleListenClosed = exports.ArticleExported = exports.AlertsManagementViewed = exports.AlertsInboxViewed = exports.AddedToWorkspace = exports.AcademicRegistration = exports.Group = exports.Identify = exports.DefaultConfiguration = exports.ApiKey = void 0;
exports.SearchFormViewed = exports.SearchFormClosed = exports.SearchExportCustomized = exports.ScrollDepth = exports.SavedSearchesViewed = exports.SavedForLater = exports.ResultsDrilldownClicked = exports.RequestDemo = exports.ReportRequested = exports.RememberMe = exports.RegistrationFormViewed = exports.RegistrationFormSubmitted = exports.ReadNow = exports.ProfileViewed = exports.PreviousResultNavigated = exports.PracticeToolUsed = exports.PodcastSeriesViewed = exports.PodcastSeriesSubscribed = exports.PodcastSeriesLandingViewed = exports.PodcastEpisodePlayed = exports.PleadingRequestSubmitted = exports.PleadingRequestAuthorization = exports.PlayDepth = exports.PersonalizationModalViewed = exports.PageModified = exports.OnboardingViewed = exports.OnboardingSubmitted = exports.NoticeViewed = exports.NextResultNavigated = exports.NewsletterSubscribed = exports.NewsletterSignupViewed = exports.NewsletterSignupSubmitted = exports.NewsletterFormViewed = exports.NewsletterFormClosed = exports.NavOpened = exports.NavClosed = exports.NavClicked = exports.MultiFactorAuthenticationViewed = exports.LoginView = exports.LoginSubmitted = exports.LoadMoreClicked = exports.LearnMore = exports.LandingViewed = exports.JobRunSubmitted = exports.JobRunFormViewed = exports.InteractiveStoryViewed = exports.InteractiveClick = exports.HomepageViewed = exports.HistoricalSpendViewed = exports.ForgotUsernamePassword = void 0;
exports.ampli = exports.Ampli = exports.WorkflowViewed = exports.UserWorkflowViewed = exports.UserWorkflowExported = exports.UpsellViewed = exports.TrackAlertModified = exports.TrackAlertDeleted = exports.TrackAlertCreated = exports.TourViewed = exports.TourEnded = exports.TocViewed = exports.TocShared = exports.TocExported = exports.TableResultsFormatted = exports.SubscriberAdded = exports.SidebarTabOpen = exports.SetAsHome = exports.SelectionsSaved = exports.SearchSubmitted = exports.SearchShared = exports.SearchSaved = exports.SearchResultsViewed = exports.SearchResultsUnhidden = exports.SearchResultsSorted = exports.SearchResultsNavigated = exports.SearchResultsExported = exports.SearchResultsClicked = exports.SearchResultHidden = void 0;
var amplitude = __importStar(require("@amplitude/analytics-browser"));
exports.ApiKey = {
    developmentbloomberglaw: '3d8b8ba9d78634dbf8a667fcec511e5d',
    developmentnewsstandalone: '1b7306bc3dc43a809f154b3277911d13',
    developmenttaxsoftware: '43e1e8a85091c4c89690c08473a5c32b',
    productiontaxsoftware: '9319e11fe19f02df34806897aff50147',
    productionnewsstandalone: '6c4173f7a8e22f7ffbf24eb3303a9b8b',
    productionbloomberglaw: '1e1ad7505970cfda0d7c016085cafe2a',
    developmentbloombergtax: 'fd2835efceef98efe31953a5bfffc673',
    productionbloombergtax: 'a86b3ce6846defb68cfe0634a7f8c3c3',
    developmentbloomberggovernment: 'ba56ea4c7e7340a0f80f3526aa20ab6f',
    productionbloomberggovernment: '32777361ca58c27790e22eb09107aa88'
};
/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
exports.DefaultConfiguration = __assign({ plan: {
        version: '47',
        branch: 'main',
        source: 'web',
        versionId: '26bdf847-6d5a-4c4e-9122-74a017753afc'
    } }, {
    ingestionMetadata: {
        sourceName: 'browser-typescript-ampli',
        sourceVersion: '2.0.0'
    }
});
var Identify = /** @class */ (function () {
    function Identify(event_properties) {
        this.event_properties = event_properties;
        this.event_type = amplitude.Types.SpecialEventType.IDENTIFY;
        this.event_properties = event_properties;
    }
    return Identify;
}());
exports.Identify = Identify;
var Group = /** @class */ (function () {
    function Group(event_properties) {
        this.event_properties = event_properties;
        this.event_type = amplitude.Types.SpecialEventType.GROUP_IDENTIFY;
        this.event_properties = event_properties;
    }
    return Group;
}());
exports.Group = Group;
var AcademicRegistration = /** @class */ (function () {
    function AcademicRegistration(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Academic Registration';
        this.event_properties = event_properties;
    }
    return AcademicRegistration;
}());
exports.AcademicRegistration = AcademicRegistration;
var AddedToWorkspace = /** @class */ (function () {
    function AddedToWorkspace(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Added to Workspace';
        this.event_properties = event_properties;
    }
    return AddedToWorkspace;
}());
exports.AddedToWorkspace = AddedToWorkspace;
var AlertsInboxViewed = /** @class */ (function () {
    function AlertsInboxViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Alerts Inbox Viewed';
        this.event_properties = event_properties;
    }
    return AlertsInboxViewed;
}());
exports.AlertsInboxViewed = AlertsInboxViewed;
var AlertsManagementViewed = /** @class */ (function () {
    function AlertsManagementViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Alerts Management Viewed';
        this.event_properties = event_properties;
    }
    return AlertsManagementViewed;
}());
exports.AlertsManagementViewed = AlertsManagementViewed;
var ArticleExported = /** @class */ (function () {
    function ArticleExported(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Article Exported';
        this.event_properties = event_properties;
    }
    return ArticleExported;
}());
exports.ArticleExported = ArticleExported;
var ArticleListenClosed = /** @class */ (function () {
    function ArticleListenClosed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Article Listen Closed';
        this.event_properties = event_properties;
    }
    return ArticleListenClosed;
}());
exports.ArticleListenClosed = ArticleListenClosed;
var ArticleListenOpened = /** @class */ (function () {
    function ArticleListenOpened(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Article Listen Opened';
        this.event_properties = event_properties;
    }
    return ArticleListenOpened;
}());
exports.ArticleListenOpened = ArticleListenOpened;
var ArticleListenPaused = /** @class */ (function () {
    function ArticleListenPaused(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Article Listen Paused';
        this.event_properties = event_properties;
    }
    return ArticleListenPaused;
}());
exports.ArticleListenPaused = ArticleListenPaused;
var ArticleListenPlayed = /** @class */ (function () {
    function ArticleListenPlayed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Article Listen Played';
        this.event_properties = event_properties;
    }
    return ArticleListenPlayed;
}());
exports.ArticleListenPlayed = ArticleListenPlayed;
var ArticleListenSkippedBack = /** @class */ (function () {
    function ArticleListenSkippedBack(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Article Listen Skipped Back';
        this.event_properties = event_properties;
    }
    return ArticleListenSkippedBack;
}());
exports.ArticleListenSkippedBack = ArticleListenSkippedBack;
var ArticleListenSkippedForward = /** @class */ (function () {
    function ArticleListenSkippedForward(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Article Listen Skipped Forward';
        this.event_properties = event_properties;
    }
    return ArticleListenSkippedForward;
}());
exports.ArticleListenSkippedForward = ArticleListenSkippedForward;
var ArticleSaved = /** @class */ (function () {
    function ArticleSaved(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Article Saved';
        this.event_properties = event_properties;
    }
    return ArticleSaved;
}());
exports.ArticleSaved = ArticleSaved;
var ArticleShared = /** @class */ (function () {
    function ArticleShared(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Article Shared';
        this.event_properties = event_properties;
    }
    return ArticleShared;
}());
exports.ArticleShared = ArticleShared;
var ArticleViewed = /** @class */ (function () {
    function ArticleViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Article Viewed';
        this.event_properties = event_properties;
    }
    return ArticleViewed;
}());
exports.ArticleViewed = ArticleViewed;
var CalendarEventViewed = /** @class */ (function () {
    function CalendarEventViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Calendar Event Viewed';
        this.event_properties = event_properties;
    }
    return CalendarEventViewed;
}());
exports.CalendarEventViewed = CalendarEventViewed;
var ChartBuilderViewed = /** @class */ (function () {
    function ChartBuilderViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Chart Builder Viewed';
        this.event_properties = event_properties;
    }
    return ChartBuilderViewed;
}());
exports.ChartBuilderViewed = ChartBuilderViewed;
var ChartDisplayModified = /** @class */ (function () {
    function ChartDisplayModified(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Chart Display Modified';
        this.event_properties = event_properties;
    }
    return ChartDisplayModified;
}());
exports.ChartDisplayModified = ChartDisplayModified;
var ChartExported = /** @class */ (function () {
    function ChartExported(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Chart Exported';
        this.event_properties = event_properties;
    }
    return ChartExported;
}());
exports.ChartExported = ChartExported;
var ChartFavorited = /** @class */ (function () {
    function ChartFavorited(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Chart Favorited';
        this.event_properties = event_properties;
    }
    return ChartFavorited;
}());
exports.ChartFavorited = ChartFavorited;
var ChartHidden = /** @class */ (function () {
    function ChartHidden(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Chart Hidden';
        this.event_properties = event_properties;
    }
    return ChartHidden;
}());
exports.ChartHidden = ChartHidden;
var ChartShown = /** @class */ (function () {
    function ChartShown(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Chart Shown';
        this.event_properties = event_properties;
    }
    return ChartShown;
}());
exports.ChartShown = ChartShown;
var ChartViewed = /** @class */ (function () {
    function ChartViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Chart Viewed';
        this.event_properties = event_properties;
    }
    return ChartViewed;
}());
exports.ChartViewed = ChartViewed;
var ConfirmationFormViewed = /** @class */ (function () {
    function ConfirmationFormViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Confirmation Form Viewed';
        this.event_properties = event_properties;
    }
    return ConfirmationFormViewed;
}());
exports.ConfirmationFormViewed = ConfirmationFormViewed;
var ContentFavorited = /** @class */ (function () {
    function ContentFavorited(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Content Favorited';
        this.event_properties = event_properties;
    }
    return ContentFavorited;
}());
exports.ContentFavorited = ContentFavorited;
var ContractTaskPaneViewed = /** @class */ (function () {
    function ContractTaskPaneViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Contract Task Pane Viewed';
        this.event_properties = event_properties;
    }
    return ContractTaskPaneViewed;
}());
exports.ContractTaskPaneViewed = ContractTaskPaneViewed;
var DashboardViewed = /** @class */ (function () {
    function DashboardViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Dashboard Viewed';
        this.event_properties = event_properties;
    }
    return DashboardViewed;
}());
exports.DashboardViewed = DashboardViewed;
var DirectoryViewed = /** @class */ (function () {
    function DirectoryViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Directory Viewed';
        this.event_properties = event_properties;
    }
    return DirectoryViewed;
}());
exports.DirectoryViewed = DirectoryViewed;
var DocketEntriesFiltered = /** @class */ (function () {
    function DocketEntriesFiltered(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Docket Entries Filtered';
        this.event_properties = event_properties;
    }
    return DocketEntriesFiltered;
}());
exports.DocketEntriesFiltered = DocketEntriesFiltered;
var DocketTrackAlertCreated = /** @class */ (function () {
    function DocketTrackAlertCreated(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Docket Track Alert Created';
        this.event_properties = event_properties;
    }
    return DocketTrackAlertCreated;
}());
exports.DocketTrackAlertCreated = DocketTrackAlertCreated;
var DocketTrackDeleted = /** @class */ (function () {
    function DocketTrackDeleted(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Docket Track Deleted';
        this.event_properties = event_properties;
    }
    return DocketTrackDeleted;
}());
exports.DocketTrackDeleted = DocketTrackDeleted;
var DocketTrackModalViewed = /** @class */ (function () {
    function DocketTrackModalViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Docket Track Modal Viewed';
        this.event_properties = event_properties;
    }
    return DocketTrackModalViewed;
}());
exports.DocketTrackModalViewed = DocketTrackModalViewed;
var DocketTrackModified = /** @class */ (function () {
    function DocketTrackModified(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Docket Track Modified';
        this.event_properties = event_properties;
    }
    return DocketTrackModified;
}());
exports.DocketTrackModified = DocketTrackModified;
var DocketUpdateAuthorization = /** @class */ (function () {
    function DocketUpdateAuthorization(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Docket Update Authorization';
        this.event_properties = event_properties;
    }
    return DocketUpdateAuthorization;
}());
exports.DocketUpdateAuthorization = DocketUpdateAuthorization;
var DocketUpdateSubmitted = /** @class */ (function () {
    function DocketUpdateSubmitted(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Docket Update Submitted';
        this.event_properties = event_properties;
    }
    return DocketUpdateSubmitted;
}());
exports.DocketUpdateSubmitted = DocketUpdateSubmitted;
var DocumentCompared = /** @class */ (function () {
    function DocumentCompared(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Document Compared';
        this.event_properties = event_properties;
    }
    return DocumentCompared;
}());
exports.DocumentCompared = DocumentCompared;
var DocumentExported = /** @class */ (function () {
    function DocumentExported(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Document Exported';
        this.event_properties = event_properties;
    }
    return DocumentExported;
}());
exports.DocumentExported = DocumentExported;
var DocumentFavorited = /** @class */ (function () {
    function DocumentFavorited(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Document Favorited';
        this.event_properties = event_properties;
    }
    return DocumentFavorited;
}());
exports.DocumentFavorited = DocumentFavorited;
var DocumentSaved = /** @class */ (function () {
    function DocumentSaved(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Document Saved';
        this.event_properties = event_properties;
    }
    return DocumentSaved;
}());
exports.DocumentSaved = DocumentSaved;
var DocumentShared = /** @class */ (function () {
    function DocumentShared(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Document Shared';
        this.event_properties = event_properties;
    }
    return DocumentShared;
}());
exports.DocumentShared = DocumentShared;
var DocumentViewed = /** @class */ (function () {
    function DocumentViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Document Viewed';
        this.event_properties = event_properties;
    }
    return DocumentViewed;
}());
exports.DocumentViewed = DocumentViewed;
var ErrorViewed = /** @class */ (function () {
    function ErrorViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Error Viewed';
        this.event_properties = event_properties;
    }
    return ErrorViewed;
}());
exports.ErrorViewed = ErrorViewed;
var FeedbackFormSubmitted = /** @class */ (function () {
    function FeedbackFormSubmitted(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Feedback Form Submitted';
        this.event_properties = event_properties;
    }
    return FeedbackFormSubmitted;
}());
exports.FeedbackFormSubmitted = FeedbackFormSubmitted;
var FeedbackFormViewed = /** @class */ (function () {
    function FeedbackFormViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Feedback Form Viewed';
        this.event_properties = event_properties;
    }
    return FeedbackFormViewed;
}());
exports.FeedbackFormViewed = FeedbackFormViewed;
var FindAlertCreated = /** @class */ (function () {
    function FindAlertCreated(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Find Alert Created';
        this.event_properties = event_properties;
    }
    return FindAlertCreated;
}());
exports.FindAlertCreated = FindAlertCreated;
var FindAlertDeleted = /** @class */ (function () {
    function FindAlertDeleted(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Find Alert Deleted';
        this.event_properties = event_properties;
    }
    return FindAlertDeleted;
}());
exports.FindAlertDeleted = FindAlertDeleted;
var FindAlertModified = /** @class */ (function () {
    function FindAlertModified(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Find Alert Modified';
        this.event_properties = event_properties;
    }
    return FindAlertModified;
}());
exports.FindAlertModified = FindAlertModified;
var ForgotUsernamePassword = /** @class */ (function () {
    function ForgotUsernamePassword(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Forgot Username-Password';
        this.event_properties = event_properties;
    }
    return ForgotUsernamePassword;
}());
exports.ForgotUsernamePassword = ForgotUsernamePassword;
var HistoricalSpendViewed = /** @class */ (function () {
    function HistoricalSpendViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Historical Spend Viewed';
        this.event_properties = event_properties;
    }
    return HistoricalSpendViewed;
}());
exports.HistoricalSpendViewed = HistoricalSpendViewed;
var HomepageViewed = /** @class */ (function () {
    function HomepageViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Homepage Viewed';
        this.event_properties = event_properties;
    }
    return HomepageViewed;
}());
exports.HomepageViewed = HomepageViewed;
var InteractiveClick = /** @class */ (function () {
    function InteractiveClick(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Interactive Click';
        this.event_properties = event_properties;
    }
    return InteractiveClick;
}());
exports.InteractiveClick = InteractiveClick;
var InteractiveStoryViewed = /** @class */ (function () {
    function InteractiveStoryViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Interactive Story Viewed';
        this.event_properties = event_properties;
    }
    return InteractiveStoryViewed;
}());
exports.InteractiveStoryViewed = InteractiveStoryViewed;
var JobRunFormViewed = /** @class */ (function () {
    function JobRunFormViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Job Run Form Viewed';
        this.event_properties = event_properties;
    }
    return JobRunFormViewed;
}());
exports.JobRunFormViewed = JobRunFormViewed;
var JobRunSubmitted = /** @class */ (function () {
    function JobRunSubmitted(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Job Run Submitted';
        this.event_properties = event_properties;
    }
    return JobRunSubmitted;
}());
exports.JobRunSubmitted = JobRunSubmitted;
var LandingViewed = /** @class */ (function () {
    function LandingViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Landing Viewed';
        this.event_properties = event_properties;
    }
    return LandingViewed;
}());
exports.LandingViewed = LandingViewed;
var LearnMore = /** @class */ (function () {
    function LearnMore(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Learn More';
        this.event_properties = event_properties;
    }
    return LearnMore;
}());
exports.LearnMore = LearnMore;
var LoadMoreClicked = /** @class */ (function () {
    function LoadMoreClicked(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Load More Clicked';
        this.event_properties = event_properties;
    }
    return LoadMoreClicked;
}());
exports.LoadMoreClicked = LoadMoreClicked;
var LoginSubmitted = /** @class */ (function () {
    function LoginSubmitted(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Login Submitted';
        this.event_properties = event_properties;
    }
    return LoginSubmitted;
}());
exports.LoginSubmitted = LoginSubmitted;
var LoginView = /** @class */ (function () {
    function LoginView(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Login View';
        this.event_properties = event_properties;
    }
    return LoginView;
}());
exports.LoginView = LoginView;
var MultiFactorAuthenticationViewed = /** @class */ (function () {
    function MultiFactorAuthenticationViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Multi Factor Authentication Viewed';
        this.event_properties = event_properties;
    }
    return MultiFactorAuthenticationViewed;
}());
exports.MultiFactorAuthenticationViewed = MultiFactorAuthenticationViewed;
var NavClicked = /** @class */ (function () {
    function NavClicked(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Nav Clicked';
        this.event_properties = event_properties;
    }
    return NavClicked;
}());
exports.NavClicked = NavClicked;
var NavClosed = /** @class */ (function () {
    function NavClosed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Nav Closed';
        this.event_properties = event_properties;
    }
    return NavClosed;
}());
exports.NavClosed = NavClosed;
var NavOpened = /** @class */ (function () {
    function NavOpened(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Nav Opened';
        this.event_properties = event_properties;
    }
    return NavOpened;
}());
exports.NavOpened = NavOpened;
var NewsletterFormClosed = /** @class */ (function () {
    function NewsletterFormClosed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Newsletter Form Closed';
        this.event_properties = event_properties;
    }
    return NewsletterFormClosed;
}());
exports.NewsletterFormClosed = NewsletterFormClosed;
var NewsletterFormViewed = /** @class */ (function () {
    function NewsletterFormViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Newsletter Form Viewed';
        this.event_properties = event_properties;
    }
    return NewsletterFormViewed;
}());
exports.NewsletterFormViewed = NewsletterFormViewed;
var NewsletterSignupSubmitted = /** @class */ (function () {
    function NewsletterSignupSubmitted(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Newsletter Signup Submitted';
        this.event_properties = event_properties;
    }
    return NewsletterSignupSubmitted;
}());
exports.NewsletterSignupSubmitted = NewsletterSignupSubmitted;
var NewsletterSignupViewed = /** @class */ (function () {
    function NewsletterSignupViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Newsletter Signup Viewed';
        this.event_properties = event_properties;
    }
    return NewsletterSignupViewed;
}());
exports.NewsletterSignupViewed = NewsletterSignupViewed;
var NewsletterSubscribed = /** @class */ (function () {
    function NewsletterSubscribed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Newsletter Subscribed';
        this.event_properties = event_properties;
    }
    return NewsletterSubscribed;
}());
exports.NewsletterSubscribed = NewsletterSubscribed;
var NextResultNavigated = /** @class */ (function () {
    function NextResultNavigated(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Next Result Navigated';
        this.event_properties = event_properties;
    }
    return NextResultNavigated;
}());
exports.NextResultNavigated = NextResultNavigated;
var NoticeViewed = /** @class */ (function () {
    function NoticeViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Notice Viewed';
        this.event_properties = event_properties;
    }
    return NoticeViewed;
}());
exports.NoticeViewed = NoticeViewed;
var OnboardingSubmitted = /** @class */ (function () {
    function OnboardingSubmitted(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Onboarding Submitted';
        this.event_properties = event_properties;
    }
    return OnboardingSubmitted;
}());
exports.OnboardingSubmitted = OnboardingSubmitted;
var OnboardingViewed = /** @class */ (function () {
    function OnboardingViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Onboarding Viewed';
        this.event_properties = event_properties;
    }
    return OnboardingViewed;
}());
exports.OnboardingViewed = OnboardingViewed;
var PageModified = /** @class */ (function () {
    function PageModified(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Page Modified';
        this.event_properties = event_properties;
    }
    return PageModified;
}());
exports.PageModified = PageModified;
var PersonalizationModalViewed = /** @class */ (function () {
    function PersonalizationModalViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Personalization Modal Viewed';
        this.event_properties = event_properties;
    }
    return PersonalizationModalViewed;
}());
exports.PersonalizationModalViewed = PersonalizationModalViewed;
var PlayDepth = /** @class */ (function () {
    function PlayDepth(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Play Depth';
        this.event_properties = event_properties;
    }
    return PlayDepth;
}());
exports.PlayDepth = PlayDepth;
var PleadingRequestAuthorization = /** @class */ (function () {
    function PleadingRequestAuthorization(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Pleading Request Authorization';
        this.event_properties = event_properties;
    }
    return PleadingRequestAuthorization;
}());
exports.PleadingRequestAuthorization = PleadingRequestAuthorization;
var PleadingRequestSubmitted = /** @class */ (function () {
    function PleadingRequestSubmitted(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Pleading Request Submitted';
        this.event_properties = event_properties;
    }
    return PleadingRequestSubmitted;
}());
exports.PleadingRequestSubmitted = PleadingRequestSubmitted;
var PodcastEpisodePlayed = /** @class */ (function () {
    function PodcastEpisodePlayed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Podcast Episode Played';
        this.event_properties = event_properties;
    }
    return PodcastEpisodePlayed;
}());
exports.PodcastEpisodePlayed = PodcastEpisodePlayed;
var PodcastSeriesLandingViewed = /** @class */ (function () {
    function PodcastSeriesLandingViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Podcast Series Landing Viewed';
        this.event_properties = event_properties;
    }
    return PodcastSeriesLandingViewed;
}());
exports.PodcastSeriesLandingViewed = PodcastSeriesLandingViewed;
var PodcastSeriesSubscribed = /** @class */ (function () {
    function PodcastSeriesSubscribed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Podcast Series Subscribed';
        this.event_properties = event_properties;
    }
    return PodcastSeriesSubscribed;
}());
exports.PodcastSeriesSubscribed = PodcastSeriesSubscribed;
var PodcastSeriesViewed = /** @class */ (function () {
    function PodcastSeriesViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Podcast Series Viewed';
        this.event_properties = event_properties;
    }
    return PodcastSeriesViewed;
}());
exports.PodcastSeriesViewed = PodcastSeriesViewed;
var PracticeToolUsed = /** @class */ (function () {
    function PracticeToolUsed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Practice Tool Used';
        this.event_properties = event_properties;
    }
    return PracticeToolUsed;
}());
exports.PracticeToolUsed = PracticeToolUsed;
var PreviousResultNavigated = /** @class */ (function () {
    function PreviousResultNavigated(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Previous Result Navigated';
        this.event_properties = event_properties;
    }
    return PreviousResultNavigated;
}());
exports.PreviousResultNavigated = PreviousResultNavigated;
var ProfileViewed = /** @class */ (function () {
    function ProfileViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Profile Viewed';
        this.event_properties = event_properties;
    }
    return ProfileViewed;
}());
exports.ProfileViewed = ProfileViewed;
var ReadNow = /** @class */ (function () {
    function ReadNow(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Read Now';
        this.event_properties = event_properties;
    }
    return ReadNow;
}());
exports.ReadNow = ReadNow;
var RegistrationFormSubmitted = /** @class */ (function () {
    function RegistrationFormSubmitted(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Registration Form Submitted';
        this.event_properties = event_properties;
    }
    return RegistrationFormSubmitted;
}());
exports.RegistrationFormSubmitted = RegistrationFormSubmitted;
var RegistrationFormViewed = /** @class */ (function () {
    function RegistrationFormViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Registration Form Viewed';
        this.event_properties = event_properties;
    }
    return RegistrationFormViewed;
}());
exports.RegistrationFormViewed = RegistrationFormViewed;
var RememberMe = /** @class */ (function () {
    function RememberMe(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Remember Me';
        this.event_properties = event_properties;
    }
    return RememberMe;
}());
exports.RememberMe = RememberMe;
var ReportRequested = /** @class */ (function () {
    function ReportRequested(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Report Requested';
        this.event_properties = event_properties;
    }
    return ReportRequested;
}());
exports.ReportRequested = ReportRequested;
var RequestDemo = /** @class */ (function () {
    function RequestDemo(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Request Demo';
        this.event_properties = event_properties;
    }
    return RequestDemo;
}());
exports.RequestDemo = RequestDemo;
var ResultsDrilldownClicked = /** @class */ (function () {
    function ResultsDrilldownClicked(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Results Drilldown Clicked';
        this.event_properties = event_properties;
    }
    return ResultsDrilldownClicked;
}());
exports.ResultsDrilldownClicked = ResultsDrilldownClicked;
var SavedForLater = /** @class */ (function () {
    function SavedForLater(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Saved for Later';
        this.event_properties = event_properties;
    }
    return SavedForLater;
}());
exports.SavedForLater = SavedForLater;
var SavedSearchesViewed = /** @class */ (function () {
    function SavedSearchesViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Saved Searches Viewed';
        this.event_properties = event_properties;
    }
    return SavedSearchesViewed;
}());
exports.SavedSearchesViewed = SavedSearchesViewed;
var ScrollDepth = /** @class */ (function () {
    function ScrollDepth(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Scroll Depth';
        this.event_properties = event_properties;
    }
    return ScrollDepth;
}());
exports.ScrollDepth = ScrollDepth;
var SearchExportCustomized = /** @class */ (function () {
    function SearchExportCustomized(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Search Export Customized';
        this.event_properties = event_properties;
    }
    return SearchExportCustomized;
}());
exports.SearchExportCustomized = SearchExportCustomized;
var SearchFormClosed = /** @class */ (function () {
    function SearchFormClosed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Search Form Closed';
        this.event_properties = event_properties;
    }
    return SearchFormClosed;
}());
exports.SearchFormClosed = SearchFormClosed;
var SearchFormViewed = /** @class */ (function () {
    function SearchFormViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Search Form Viewed';
        this.event_properties = event_properties;
    }
    return SearchFormViewed;
}());
exports.SearchFormViewed = SearchFormViewed;
var SearchResultHidden = /** @class */ (function () {
    function SearchResultHidden(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Search Result Hidden';
        this.event_properties = event_properties;
    }
    return SearchResultHidden;
}());
exports.SearchResultHidden = SearchResultHidden;
var SearchResultsClicked = /** @class */ (function () {
    function SearchResultsClicked(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Search Results Clicked';
        this.event_properties = event_properties;
    }
    return SearchResultsClicked;
}());
exports.SearchResultsClicked = SearchResultsClicked;
var SearchResultsExported = /** @class */ (function () {
    function SearchResultsExported(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Search Results Exported';
        this.event_properties = event_properties;
    }
    return SearchResultsExported;
}());
exports.SearchResultsExported = SearchResultsExported;
var SearchResultsNavigated = /** @class */ (function () {
    function SearchResultsNavigated(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Search Results Navigated';
        this.event_properties = event_properties;
    }
    return SearchResultsNavigated;
}());
exports.SearchResultsNavigated = SearchResultsNavigated;
var SearchResultsSorted = /** @class */ (function () {
    function SearchResultsSorted(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Search Results Sorted';
        this.event_properties = event_properties;
    }
    return SearchResultsSorted;
}());
exports.SearchResultsSorted = SearchResultsSorted;
var SearchResultsUnhidden = /** @class */ (function () {
    function SearchResultsUnhidden(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Search Results Unhidden';
        this.event_properties = event_properties;
    }
    return SearchResultsUnhidden;
}());
exports.SearchResultsUnhidden = SearchResultsUnhidden;
var SearchResultsViewed = /** @class */ (function () {
    function SearchResultsViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Search Results Viewed';
        this.event_properties = event_properties;
    }
    return SearchResultsViewed;
}());
exports.SearchResultsViewed = SearchResultsViewed;
var SearchSaved = /** @class */ (function () {
    function SearchSaved(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Search Saved';
        this.event_properties = event_properties;
    }
    return SearchSaved;
}());
exports.SearchSaved = SearchSaved;
var SearchShared = /** @class */ (function () {
    function SearchShared(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Search Shared';
        this.event_properties = event_properties;
    }
    return SearchShared;
}());
exports.SearchShared = SearchShared;
var SearchSubmitted = /** @class */ (function () {
    function SearchSubmitted(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Search Submitted';
        this.event_properties = event_properties;
    }
    return SearchSubmitted;
}());
exports.SearchSubmitted = SearchSubmitted;
var SelectionsSaved = /** @class */ (function () {
    function SelectionsSaved(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Selections Saved';
        this.event_properties = event_properties;
    }
    return SelectionsSaved;
}());
exports.SelectionsSaved = SelectionsSaved;
var SetAsHome = /** @class */ (function () {
    function SetAsHome(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Set as Home';
        this.event_properties = event_properties;
    }
    return SetAsHome;
}());
exports.SetAsHome = SetAsHome;
var SidebarTabOpen = /** @class */ (function () {
    function SidebarTabOpen(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Sidebar Tab Open';
        this.event_properties = event_properties;
    }
    return SidebarTabOpen;
}());
exports.SidebarTabOpen = SidebarTabOpen;
var SubscriberAdded = /** @class */ (function () {
    function SubscriberAdded(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Subscriber Added';
        this.event_properties = event_properties;
    }
    return SubscriberAdded;
}());
exports.SubscriberAdded = SubscriberAdded;
var TableResultsFormatted = /** @class */ (function () {
    function TableResultsFormatted(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Table Results Formatted';
        this.event_properties = event_properties;
    }
    return TableResultsFormatted;
}());
exports.TableResultsFormatted = TableResultsFormatted;
var TocExported = /** @class */ (function () {
    function TocExported(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'TOC Exported';
        this.event_properties = event_properties;
    }
    return TocExported;
}());
exports.TocExported = TocExported;
var TocShared = /** @class */ (function () {
    function TocShared(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'TOC Shared';
        this.event_properties = event_properties;
    }
    return TocShared;
}());
exports.TocShared = TocShared;
var TocViewed = /** @class */ (function () {
    function TocViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'TOC Viewed';
        this.event_properties = event_properties;
    }
    return TocViewed;
}());
exports.TocViewed = TocViewed;
var TourEnded = /** @class */ (function () {
    function TourEnded(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Tour Ended';
        this.event_properties = event_properties;
    }
    return TourEnded;
}());
exports.TourEnded = TourEnded;
var TourViewed = /** @class */ (function () {
    function TourViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Tour Viewed';
        this.event_properties = event_properties;
    }
    return TourViewed;
}());
exports.TourViewed = TourViewed;
var TrackAlertCreated = /** @class */ (function () {
    function TrackAlertCreated(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Track Alert Created';
        this.event_properties = event_properties;
    }
    return TrackAlertCreated;
}());
exports.TrackAlertCreated = TrackAlertCreated;
var TrackAlertDeleted = /** @class */ (function () {
    function TrackAlertDeleted(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Track Alert Deleted';
        this.event_properties = event_properties;
    }
    return TrackAlertDeleted;
}());
exports.TrackAlertDeleted = TrackAlertDeleted;
var TrackAlertModified = /** @class */ (function () {
    function TrackAlertModified(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Track Alert Modified';
        this.event_properties = event_properties;
    }
    return TrackAlertModified;
}());
exports.TrackAlertModified = TrackAlertModified;
var UpsellViewed = /** @class */ (function () {
    function UpsellViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Upsell Viewed';
        this.event_properties = event_properties;
    }
    return UpsellViewed;
}());
exports.UpsellViewed = UpsellViewed;
var UserWorkflowExported = /** @class */ (function () {
    function UserWorkflowExported(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'User Workflow Exported';
        this.event_properties = event_properties;
    }
    return UserWorkflowExported;
}());
exports.UserWorkflowExported = UserWorkflowExported;
var UserWorkflowViewed = /** @class */ (function () {
    function UserWorkflowViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'User Workflow Viewed';
        this.event_properties = event_properties;
    }
    return UserWorkflowViewed;
}());
exports.UserWorkflowViewed = UserWorkflowViewed;
var WorkflowViewed = /** @class */ (function () {
    function WorkflowViewed(event_properties) {
        this.event_properties = event_properties;
        this.event_type = 'Workflow Viewed';
        this.event_properties = event_properties;
    }
    return WorkflowViewed;
}());
exports.WorkflowViewed = WorkflowViewed;
var getVoidPromiseResult = function () { return ({ promise: Promise.resolve() }); };
// prettier-ignore
var Ampli = /** @class */ (function () {
    function Ampli() {
        this.disabled = false;
    }
    Object.defineProperty(Ampli.prototype, "client", {
        get: function () {
            this.isInitializedAndEnabled();
            return this.amplitude;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Ampli.prototype, "isLoaded", {
        get: function () {
            return this.amplitude != null;
        },
        enumerable: false,
        configurable: true
    });
    Ampli.prototype.isInitializedAndEnabled = function () {
        if (!this.amplitude) {
            console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
            return false;
        }
        return !this.disabled;
    };
    /**
     * Initialize the Ampli SDK. Call once when your application starts.
     *
     * @param options Configuration options to initialize the Ampli SDK with.
     */
    Ampli.prototype.load = function (options) {
        var _a;
        this.disabled = (_a = options.disabled) !== null && _a !== void 0 ? _a : false;
        if (this.amplitude) {
            console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
            return getVoidPromiseResult();
        }
        var apiKey = null;
        if (options.client && 'apiKey' in options.client) {
            apiKey = options.client.apiKey;
        }
        else if ('environment' in options) {
            apiKey = exports.ApiKey[options.environment];
        }
        if (options.client && 'instance' in options.client) {
            this.amplitude = options.client.instance;
        }
        else if (apiKey) {
            this.amplitude = amplitude.createInstance();
            var configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
            return this.amplitude.init(apiKey, undefined, __assign(__assign({}, exports.DefaultConfiguration), configuration));
        }
        else {
            console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
        }
        return getVoidPromiseResult();
    };
    /**
     * Identify a user and set user properties.
     *
     * @param userId The user's id.
     * @param properties The user properties.
     * @param options Optional event options.
     */
    Ampli.prototype.identify = function (userId, properties, options) {
        if (!this.isInitializedAndEnabled()) {
            return getVoidPromiseResult();
        }
        if (userId) {
            options = __assign(__assign({}, options), { user_id: userId });
        }
        var amplitudeIdentify = new amplitude.Identify();
        var eventProperties = properties;
        if (eventProperties != null) {
            for (var _i = 0, _a = Object.entries(eventProperties); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], value = _b[1];
                amplitudeIdentify.set(key, value);
            }
        }
        return this.amplitude.identify(amplitudeIdentify, options);
    };
    /**
     * Set Group for the current user
     *
     * @param groupType The group type.
     * @param groupName The group name.
     * @param options Optional event options.
     */
    Ampli.prototype.setGroup = function (groupType, groupName, options) {
        if (!this.isInitializedAndEnabled()) {
            return getVoidPromiseResult();
        }
        return this.amplitude.setGroup(groupType, groupName, options);
    };
    /**
     * Identify a group and set group properties.
     *
     * @param groupType The group type.
     * @param groupName The group name.
     * @param properties The group properties.
     * @param options Optional event options.
     */
    Ampli.prototype.groupIdentify = function (groupType, groupName, properties, options) {
        if (!this.isInitializedAndEnabled()) {
            return getVoidPromiseResult();
        }
        var amplitudeIdentify = new amplitude.Identify();
        var eventProperties = properties;
        if (eventProperties != null) {
            for (var _i = 0, _a = Object.entries(eventProperties); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], value = _b[1];
                amplitudeIdentify.set(key, value);
            }
        }
        return this.amplitude.groupIdentify(groupType, groupName, amplitudeIdentify, options);
    };
    /**
     * Flush the event.
     */
    Ampli.prototype.flush = function () {
        if (!this.isInitializedAndEnabled()) {
            return getVoidPromiseResult();
        }
        return this.amplitude.flush();
    };
    /**
     * Track event
     *
     * @param event The event to track.
     * @param options Optional event options.
     */
    Ampli.prototype.track = function (event, options) {
        if (!this.isInitializedAndEnabled()) {
            return getVoidPromiseResult();
        }
        return this.amplitude.track(event, undefined, options);
    };
    /**
     * Academic Registration
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Academic%20Registration)
     *
     * [Login Page]()
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.academicRegistration = function (properties, options) {
        return this.track(new AcademicRegistration(properties), options);
    };
    /**
     * Added to Workspace
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Added%20to%20Workspace)
     *
     * User adds a search or a document to a workspace. Carries properties of the specific item (document, search, etc) added
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. agency)
     * @param options Amplitude event options.
     */
    Ampli.prototype.addedToWorkspace = function (properties, options) {
        return this.track(new AddedToWorkspace(properties), options);
    };
    /**
     * Alerts Inbox Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Alerts%20Inbox%20Viewed)
     *
     * [Alerts Inbox](https://www.bloomberglaw.com/product/blaw/alerts/inbox)
     *
     * Owner: dylan.blass-svedvik@searchdiscovery.com
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.alertsInboxViewed = function (properties, options) {
        return this.track(new AlertsInboxViewed(properties), options);
    };
    /**
     * Alerts Management Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Alerts%20Management%20Viewed)
     *
     * Alerts Management Viewed
     *
     * * [BLAW](https://www.bloomberglaw.com/product/blaw/alerts/management)
     *
     * * [BTAX]()
     *
     * * [BGOV]()
     *
     * Owner: dylan.blass-svedvik@searchdiscovery.com
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.alertsManagementViewed = function (properties, options) {
        return this.track(new AlertsManagementViewed(properties), options);
    };
    /**
     * Article Exported
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Exported)
     *
     * User exports an article, usually using download or print links.
     *
     * Owner: Rachel Carmichael
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.articleExported = function (properties, options) {
        return this.track(new ArticleExported(properties), options);
    };
    /**
     * Article Listen Closed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Listen%20Closed)
     *
     * Fire when the user clicks the "X" to close the listen UI.
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.articleListenClosed = function (properties, options) {
        return this.track(new ArticleListenClosed(properties), options);
    };
    /**
     * Article Listen Opened
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Listen%20Opened)
     *
     * User clicks on the Article Listen button either on the email or web page.
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.articleListenOpened = function (properties, options) {
        return this.track(new ArticleListenOpened(properties), options);
    };
    /**
     * Article Listen Paused
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Listen%20Paused)
     *
     * User presses the "pause" button in the audio player UI
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.articleListenPaused = function (properties, options) {
        return this.track(new ArticleListenPaused(properties), options);
    };
    /**
     * Article Listen Played
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Listen%20Played)
     *
     * User presses the "play" button in the article listen UI.
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.articleListenPlayed = function (properties, options) {
        return this.track(new ArticleListenPlayed(properties), options);
    };
    /**
     * Article Listen Skipped Back
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Listen%20Skipped%20Back)
     *
     * Fire when the user skips backward in article listen UI.
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.articleListenSkippedBack = function (properties, options) {
        return this.track(new ArticleListenSkippedBack(properties), options);
    };
    /**
     * Article Listen Skipped Forward
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Listen%20Skipped%20Forward)
     *
     * Fire when the user skips forward in an article listen.
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.articleListenSkippedForward = function (properties, options) {
        return this.track(new ArticleListenSkippedForward(properties), options);
    };
    /**
     * Article Saved
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Saved)
     *
     * User saves an article.
     *
     * Owner: Rachel Carmichael
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.articleSaved = function (properties, options) {
        return this.track(new ArticleSaved(properties), options);
    };
    /**
     * Article Shared
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Shared)
     *
     * When a user shares an article via email or a social platform.
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.articleShared = function (properties, options) {
        return this.track(new ArticleShared(properties), options);
    };
    /**
     * Article Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Article%20Viewed)
     *
     * Fire when the user views a bnews article (usually on page load).
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.articleViewed = function (properties, options) {
        return this.track(new ArticleViewed(properties), options);
    };
    /**
     * Calendar Event Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Calendar%20Event%20Viewed)
     *
     * This event tracks when a user views a calendar event
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.calendarEventViewed = function (properties, options) {
        return this.track(new CalendarEventViewed(properties), options);
    };
    /**
     * Chart Builder Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Chart%20Builder%20Viewed)
     *
     * Fire when users are shown the chart builder page to build a chart (includes edit or modify)
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.chartBuilderViewed = function (properties, options) {
        return this.track(new ChartBuilderViewed(properties), options);
    };
    /**
     * Chart Display Modified
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Chart%20Display%20Modified)
     *
     * Fire when users change display options such as column headers, answer length, highlight by timeframe, etc.
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.chartDisplayModified = function (properties, options) {
        return this.track(new ChartDisplayModified(properties), options);
    };
    /**
     * Chart Exported
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Chart%20Exported)
     *
     * Fire when users export chart (include current options in parameters)
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.chartExported = function (properties, options) {
        return this.track(new ChartExported(properties), options);
    };
    /**
     * Chart Favorited
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Chart%20Favorited)
     *
     * Fire when users favorite chart options
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.chartFavorited = function (properties, options) {
        return this.track(new ChartFavorited(properties), options);
    };
    /**
     * Chart Hidden
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Chart%20Hidden)
     *
     * Fire when users toggle the show chart CTA to hide chart (e.g. historical spend)
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.chartHidden = function (properties, options) {
        return this.track(new ChartHidden(properties), options);
    };
    /**
     * Chart Shown
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Chart%20Shown)
     *
     * Fire when users toggle the Show Chart CTA to show chart (Historical Spend)
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.chartShown = function (properties, options) {
        return this.track(new ChartShown(properties), options);
    };
    /**
     * Chart Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Chart%20Viewed)
     *
     * Fire when users are shown a chart from chart builder whether new, saved, or modified.
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.chartViewed = function (properties, options) {
        return this.track(new ChartViewed(properties), options);
    };
    /**
     * Confirmation Form Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Confirmation%20Form%20Viewed)
     *
     * This event tracks when a user views the confirmation form during the sales process
     *
     * Owner: Milad Amin
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.confirmationFormViewed = function (properties, options) {
        return this.track(new ConfirmationFormViewed(properties), options);
    };
    /**
     * Content Favorited
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Content%20Favorited)
     *
     * When a user "Favorites" non-document content, content modules or tools.
     *
     * Example: News Channel Landing Pages on BLAW: https://www.bloomberglaw.com/bloomberglawnews/capital-markets
     *
     *
     * For documents - use Document Favorited
     *
     * For Charts - use Chart Favorited
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.contentFavorited = function (properties, options) {
        return this.track(new ContentFavorited(properties), options);
    };
    /**
     * Contract Task Pane Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Contract%20Task%20Pane%20Viewed)
     *
     * This event tracks when a user views the contract task pane, which displays relevant tasks and information related to a specific contract.... This is specific for the BLAW Contracts Tool
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.contractTaskPaneViewed = function (properties, options) {
        return this.track(new ContractTaskPaneViewed(properties), options);
    };
    /**
     * Dashboard Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Dashboard%20Viewed)
     *
     * This event tracks when a user views a dashboard
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.dashboardViewed = function (properties, options) {
        return this.track(new DashboardViewed(properties), options);
    };
    /**
     * Directory Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Directory%20Viewed)
     *
     * This event tracks when a user views a directory within the Bloomberg Government platform. It provides insights into user engagement with directories, helping to understand which directories are most frequently accessed and informing decisions on directory organization and content
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.directoryViewed = function (properties, options) {
        return this.track(new DirectoryViewed(properties), options);
    };
    /**
     * Docket Entries Filtered
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Docket%20Entries%20Filtered)
     *
     * Have the docket entries been [filtered](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2) on the Dockets page?
     *
     * Owner: dylan.blass-svedvik@searchdiscovery.com
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.docketEntriesFiltered = function (properties, options) {
        return this.track(new DocketEntriesFiltered(properties), options);
    };
    /**
     * Docket Track Alert Created
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Docket%20Track%20Alert%20Created)
     *
     * A [Docket Track](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2) has been created...
     *
     * Owner: dylan.blass-svedvik@searchdiscovery.com
     *
     * @param properties The event's properties (e.g. alertType)
     * @param options Amplitude event options.
     */
    Ampli.prototype.docketTrackAlertCreated = function (properties, options) {
        return this.track(new DocketTrackAlertCreated(properties), options);
    };
    /**
     * Docket Track Deleted
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Docket%20Track%20Deleted)
     *
     * The Docket Track was deleted by the user.
     *
     * Owner: dylan.blass-svedvik@searchdiscovery.com
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.docketTrackDeleted = function (properties, options) {
        return this.track(new DocketTrackDeleted(properties), options);
    };
    /**
     * Docket Track Modal Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Docket%20Track%20Modal%20Viewed)
     *
     * Impressions of the Docket Track Modal, This can be viewed on [docket](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2) or in [Custom Alerts](https://www.bloomberglaw.com/product/blaw/alerts/management)
     *
     * Owner: dylan.blass-svedvik@searchdiscovery.com
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.docketTrackModalViewed = function (properties, options) {
        return this.track(new DocketTrackModalViewed(properties), options);
    };
    /**
     * Docket Track Modified
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Docket%20Track%20Modified)
     *
     * This is only if the docket track has been [modified](https://www.bloomberglaw.com/product/blaw/alerts/management)
     *
     * Owner: dylan.blass-svedvik@searchdiscovery.com
     *
     * @param properties The event's properties (e.g. alertType)
     * @param options Amplitude event options.
     */
    Ampli.prototype.docketTrackModified = function (properties, options) {
        return this.track(new DocketTrackModified(properties), options);
    };
    /**
     * Docket Update Authorization
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Docket%20Update%20Authorization)
     *
     * [Update Docket](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2) Modal, when it has been viewed
     *
     * Owner: dylan.blass-svedvik@searchdiscovery.com
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.docketUpdateAuthorization = function (properties, options) {
        return this.track(new DocketUpdateAuthorization(properties), options);
    };
    /**
     * Docket Update Submitted
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Docket%20Update%20Submitted)
     *
     * Triggers when a user has successfully submitted a docket update from. the [Update Docket](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2) Modal
     *
     * Owner: dylan.blass-svedvik@searchdiscovery.com
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.docketUpdateSubmitted = function (properties, options) {
        return this.track(new DocketUpdateSubmitted(properties), options);
    };
    /**
     * Document Compared
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Document%20Compared)
     *
     * This event tracks when a document is compared to another document, providing insights into user behavior and document usage patterns
     *
     *
     * NOTE:
     *
     * Document properties should be associated with the primary document being compared
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. agency)
     * @param options Amplitude event options.
     */
    Ampli.prototype.documentCompared = function (properties, options) {
        return this.track(new DocumentCompared(properties), options);
    };
    /**
     * Document Exported
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Document%20Exported)
     *
     * Fire when users export a document
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. agency)
     * @param options Amplitude event options.
     */
    Ampli.prototype.documentExported = function (properties, options) {
        return this.track(new DocumentExported(properties), options);
    };
    /**
     * Document Favorited
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Document%20Favorited)
     *
     * When someone "Favorites" a document
     *
     * Owner: dylan.blass-svedvik@searchdiscovery.com
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.documentFavorited = function (properties, options) {
        return this.track(new DocumentFavorited(properties), options);
    };
    /**
     * Document Saved
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Document%20Saved)
     *
     * Fires when a user uses the "Saved for Later" functionality
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.documentSaved = function (properties, options) {
        return this.track(new DocumentSaved(properties), options);
    };
    /**
     * Document Shared
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Document%20Shared)
     *
     * A document has been shared by email
     *
     * Owner: dylan.blass-svedvik@searchdiscovery.com
     *
     * @param properties The event's properties (e.g. agency)
     * @param options Amplitude event options.
     */
    Ampli.prototype.documentShared = function (properties, options) {
        return this.track(new DocumentShared(properties), options);
    };
    /**
     * Document Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Document%20Viewed)
     *
     * When a document has been viewed
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. agency)
     * @param options Amplitude event options.
     */
    Ampli.prototype.documentViewed = function (properties, options) {
        return this.track(new DocumentViewed(properties), options);
    };
    /**
     * Error Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Error%20Viewed)
     *
     * User views a page that returns a server error (like a 404), be sure to update the httpStatusCode to match the error.
     *
     * Owner: dylan.blass-svedvik@searchdiscovery.com
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.errorViewed = function (properties, options) {
        return this.track(new ErrorViewed(properties), options);
    };
    /**
     * Feedback Form Submitted
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Feedback%20Form%20Submitted)
     *
     * Fires when a user submits a feedback form
     *
     * Owner: Kyle Contrata
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.feedbackFormSubmitted = function (properties, options) {
        return this.track(new FeedbackFormSubmitted(properties), options);
    };
    /**
     * Feedback Form Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Feedback%20Form%20Viewed)
     *
     * Fires when a user views or is shown a feedback form.
     *
     * Owner: Kyle Contrata
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.feedbackFormViewed = function (properties, options) {
        return this.track(new FeedbackFormViewed(properties), options);
    };
    /**
     * Find Alert Created
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Find%20Alert%20Created)
     *
     * This event tracks the creation of a new alert in the Alert Management system.  A Find Alert is associated with the user setting an alert based on a search they have performed.
     *
     * Owner: dylan.blass-svedvik@searchdiscovery.com
     *
     * @param properties The event's properties (e.g. alertClass)
     * @param options Amplitude event options.
     */
    Ampli.prototype.findAlertCreated = function (properties, options) {
        return this.track(new FindAlertCreated(properties), options);
    };
    /**
     * Find Alert Deleted
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Find%20Alert%20Deleted)
     *
     * The alert was deleted by the user
     *
     * Owner: dylan.blass-svedvik@searchdiscovery.com
     *
     * @param properties The event's properties (e.g. alertClass)
     * @param options Amplitude event options.
     */
    Ampli.prototype.findAlertDeleted = function (properties, options) {
        return this.track(new FindAlertDeleted(properties), options);
    };
    /**
     * Find Alert Modified
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Find%20Alert%20Modified)
     *
     * This would be fired when the "Save Search to Alert" is updated... While editing an Alert
     *
     * Owner: dylan.blass-svedvik@searchdiscovery.com
     *
     * @param properties The event's properties (e.g. alertClass)
     * @param options Amplitude event options.
     */
    Ampli.prototype.findAlertModified = function (properties, options) {
        return this.track(new FindAlertModified(properties), options);
    };
    /**
     * Forgot Username-Password
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Forgot%20Username-Password)
     *
     * Clicking on Forgot Username/Password on [login page]().
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.forgotUsernamePassword = function (properties, options) {
        return this.track(new ForgotUsernamePassword(properties), options);
    };
    /**
     * Historical Spend Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Historical%20Spend%20Viewed)
     *
     * When user loads the Historical Spend Page
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.historicalSpendViewed = function (properties, options) {
        return this.track(new HistoricalSpendViewed(properties), options);
    };
    /**
     * Homepage Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Homepage%20Viewed)
     *
     * User views the homepage
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.homepageViewed = function (properties, options) {
        return this.track(new HomepageViewed(properties), options);
    };
    /**
     * Interactive Click
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Interactive%20Click)
     *
     * This should be used for any important interaction on a page or modal, that hasn't justified a specific event and doesn't cause a new page load (not a navigation). An example would be a click that shows a modal or modifies a table layout.
     *
     * The concept is if these items become valuable, we'll want to more specifically define them and track them.
     *
     * Owner: dylan.blass-svedvik@searchdiscovery.com
     *
     * @param properties The event's properties (e.g. agency)
     * @param options Amplitude event options.
     */
    Ampli.prototype.interactiveClick = function (properties, options) {
        return this.track(new InteractiveClick(properties), options);
    };
    /**
     * Interactive Story Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Interactive%20Story%20Viewed)
     *
     * Fire when the user views an interactice story on bnews  (usually on page load).
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.interactiveStoryViewed = function (properties, options) {
        return this.track(new InteractiveStoryViewed(properties), options);
    };
    /**
     * Job Run Form Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Job%20Run%20Form%20Viewed)
     *
     * This event tracks when a user views the job run form in the BTAX Software Workflows
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.jobRunFormViewed = function (properties, options) {
        return this.track(new JobRunFormViewed(properties), options);
    };
    /**
     * Job Run Submitted
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Job%20Run%20Submitted)
     *
     * This event tracks the submission of job runs within the BTAX Software Workflows, providing insights into the frequency and usage patterns of this feature
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.jobRunSubmitted = function (properties, options) {
        return this.track(new JobRunSubmitted(properties), options);
    };
    /**
     * Landing Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Landing%20Viewed)
     *
     * News Landing Page (homepage or channel page) Viewed - Specific to INDG News from BWRITE.
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.landingViewed = function (properties, options) {
        return this.track(new LandingViewed(properties), options);
    };
    /**
     * Learn More
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Learn%20More)
     *
     * Learn More from [Login Page]()
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.learnMore = function (properties, options) {
        return this.track(new LearnMore(properties), options);
    };
    /**
     * Load More Clicked
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Load%20More%20Clicked)
     *
     * Load More on a search results page
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.loadMoreClicked = function (properties, options) {
        return this.track(new LoadMoreClicked(properties), options);
    };
    /**
     * Login Submitted
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Login%20Submitted)
     *
     * [Login Page](https://www.bloomberglaw.com/signin) Submitted
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.loginSubmitted = function (properties, options) {
        return this.track(new LoginSubmitted(properties), options);
    };
    /**
     * Login View
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Login%20View)
     *
     * The [login page](https://www.bloomberglaw.com/signin) has been viewed
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.loginView = function (properties, options) {
        return this.track(new LoginView(properties), options);
    };
    /**
     * Multi Factor Authentication Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Multi%20Factor%20Authentication%20Viewed)
     *
     * This event fires when users view the multi-factor authentication page on the login page.
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.multiFactorAuthenticationViewed = function (properties, options) {
        return this.track(new MultiFactorAuthenticationViewed(properties), options);
    };
    /**
     * Nav Clicked
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Nav%20Clicked)
     *
     * Trigger when a user actually uses a navigation (clicks a link to a new page)
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.navClicked = function (properties, options) {
        return this.track(new NavClicked(properties), options);
    };
    /**
     * Nav Closed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Nav%20Closed)
     *
     * Trigger when a user closes a navigational menu.
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.navClosed = function (properties, options) {
        return this.track(new NavClosed(properties), options);
    };
    /**
     * Nav Opened
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Nav%20Opened)
     *
     * Trigger when user opens a navigational menu of some sort, a hamburger, a drop down, etc.
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.navOpened = function (properties, options) {
        return this.track(new NavOpened(properties), options);
    };
    /**
     * Newsletter Form Closed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Newsletter%20Form%20Closed)
     *
     * Newsletter Signup form is closed
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.newsletterFormClosed = function (properties, options) {
        return this.track(new NewsletterFormClosed(properties), options);
    };
    /**
     * Newsletter Form Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Newsletter%20Form%20Viewed)
     *
     * Trigger when form is viewed to subscribe to newsletters.
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.newsletterFormViewed = function (properties, options) {
        return this.track(new NewsletterFormViewed(properties), options);
    };
    /**
     * Newsletter Signup Submitted
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Newsletter%20Signup%20Submitted)
     *
     * Submit sign up form for newsletter. This is the form where user enters contact info, not the form where they select which newsletter to subscribe to.
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.newsletterSignupSubmitted = function (properties, options) {
        return this.track(new NewsletterSignupSubmitted(properties), options);
    };
    /**
     * Newsletter Signup Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Newsletter%20Signup%20Viewed)
     *
     * Viewed the signup form for newsletters. This is the form where the user enters contact info (not the form where they select newsletter subscriptions).
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.newsletterSignupViewed = function (properties, options) {
        return this.track(new NewsletterSignupViewed(properties), options);
    };
    /**
     * Newsletter Subscribed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Newsletter%20Subscribed)
     *
     * User has subscribed to a newsletter(s)
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.newsletterSubscribed = function (properties, options) {
        return this.track(new NewsletterSubscribed(properties), options);
    };
    /**
     * Next Result Navigated
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Next%20Result%20Navigated)
     *
     * Click on the Next Result CTA on a [document](https://www.bloomberglaw.com/product/blaw/document/X1JQ07JTG000N?criteria\_id=84c1018ec813139841de1aa2f1912b2d&navCriteriaId=3d6cce9c45c74c474f47647a70e68a3e&searchGuid=7f0cad2c-be82-4cc7-ac6c-3b50b2c78b6b&search32=H4g51LVe0iM0p08RRFKLsA%3D%3DRYD33FJ\_mhvHoY2LKNiKiiq3JRBTESO6h3zCMx9wZZkEdpkGaFUoYq7zp2az7ldtVf0oFM7QtuA0lE80TgXIcMJ84XGFh-nt\_GolpFoLz6L2qD2B0pd4AOWnfmQP4Ith).
     *
     * Owner: dylan.blass-svedvik@searchdiscovery.com
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.nextResultNavigated = function (properties, options) {
        return this.track(new NextResultNavigated(properties), options);
    };
    /**
     * Notice Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Notice%20Viewed)
     *
     * Fires when notice for sanctioned countries is viewed
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.noticeViewed = function (properties, options) {
        return this.track(new NoticeViewed(properties), options);
    };
    /**
     * Onboarding Submitted
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Onboarding%20Submitted)
     *
     * This event fires when a user submits their onboarding information for personalization purposes.
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.onboardingSubmitted = function (properties, options) {
        return this.track(new OnboardingSubmitted(properties), options);
    };
    /**
     * Onboarding Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Onboarding%20Viewed)
     *
     * This event fires when a user views the onboarding process for personalized content. i.e., https://www.bloomberglaw.com/onboarding
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.onboardingViewed = function (properties, options) {
        return this.track(new OnboardingViewed(properties), options);
    };
    /**
     * Page Modified
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Page%20Modified)
     *
     * Fire when a user takes an action to alter how a page is displayed or laid out. For example choosing between a generic or personalised version.
     *
     * Owner: Rachel Carmichael
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.pageModified = function (properties, options) {
        return this.track(new PageModified(properties), options);
    };
    /**
     * Personalization Modal Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Personalization%20Modal%20Viewed)
     *
     * User views a personalization modal
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.personalizationModalViewed = function (properties, options) {
        return this.track(new PersonalizationModalViewed(properties), options);
    };
    /**
     * Play Depth
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Play%20Depth)
     *
     * usually quartiles, the % of the audio or video that has been played
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.playDepth = function (properties, options) {
        return this.track(new PlayDepth(properties), options);
    };
    /**
     * Pleading Request Authorization
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Pleading%20Request%20Authorization)
     *
     * A [docket entry/pleading](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2) has been approved (Accept has been pressed)
     *
     * Owner: dylan.blass-svedvik@searchdiscovery.com
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.pleadingRequestAuthorization = function (properties, options) {
        return this.track(new PleadingRequestAuthorization(properties), options);
    };
    /**
     * Pleading Request Submitted
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Pleading%20Request%20Submitted)
     *
     * When a user has accepted and submitted the [docket entry/pleading request](https://www.bloomberglaw.com/product/antitrust/document/X1Q6NKU3NNO2)...
     *
     * Owner: dylan.blass-svedvik@searchdiscovery.com
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.pleadingRequestSubmitted = function (properties, options) {
        return this.track(new PleadingRequestSubmitted(properties), options);
    };
    /**
     * Podcast Episode Played
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Podcast%20Episode%20Played)
     *
     * When the user clicks on the "Play" button for a podcast
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.podcastEpisodePlayed = function (properties, options) {
        return this.track(new PodcastEpisodePlayed(properties), options);
    };
    /**
     * Podcast Series Landing Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Podcast%20Series%20Landing%20Viewed)
     *
     * Podcast Series Landing View
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.podcastSeriesLandingViewed = function (properties, options) {
        return this.track(new PodcastSeriesLandingViewed(properties), options);
    };
    /**
     * Podcast Series Subscribed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Podcast%20Series%20Subscribed)
     *
     * This event tracks when a user subscribes to a specific podcast series
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.podcastSeriesSubscribed = function (properties, options) {
        return this.track(new PodcastSeriesSubscribed(properties), options);
    };
    /**
     * Podcast Series Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Podcast%20Series%20Viewed)
     *
     * Fire when a user loads a landing page for a specific podcast series.
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.podcastSeriesViewed = function (properties, options) {
        return this.track(new PodcastSeriesViewed(properties), options);
    };
    /**
     * Practice Tool Used
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Practice%20Tool%20Used)
     *
     * Tracks the usage of the practice tool, providing insights into how often and in what ways users engage with this tool
     *
     * Owner: Milad Amin
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.practiceToolUsed = function (properties, options) {
        return this.track(new PracticeToolUsed(properties), options);
    };
    /**
     * Previous Result Navigated
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Previous%20Result%20Navigated)
     *
     * Click on the Previous Result CTA on a [document](https://www.bloomberglaw.com/product/blaw/document/X1JQ07JTG000N?criteria\_id=84c1018ec813139841de1aa2f1912b2d&navCriteriaId=3d6cce9c45c74c474f47647a70e68a3e&searchGuid=7f0cad2c-be82-4cc7-ac6c-3b50b2c78b6b&search32=H4g51LVe0iM0p08RRFKLsA%3D%3DRYD33FJ\_mhvHoY2LKNiKiiq3JRBTESO6h3zCMx9wZZkEdpkGaFUoYq7zp2az7ldtVf0oFM7QtuA0lE80TgXIcMJ84XGFh-nt\_GolpFoLz6L2qD2B0pd4AOWnfmQP4Ith).
     *
     * Owner: dylan.blass-svedvik@searchdiscovery.com
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.previousResultNavigated = function (properties, options) {
        return this.track(new PreviousResultNavigated(properties), options);
    };
    /**
     * Profile Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Profile%20Viewed)
     *
     * This event tracks when a person or entity (company, agency, etc.) profile is viewed
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.profileViewed = function (properties, options) {
        return this.track(new ProfileViewed(properties), options);
    };
    /**
     * Read Now
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Read%20Now)
     *
     * [Login Page](https://www.bloomberglaw.com/signin)
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.readNow = function (properties, options) {
        return this.track(new ReadNow(properties), options);
    };
    /**
     * Registration Form Submitted
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Registration%20Form%20Submitted)
     *
     * This event tracks when a user submits a registration form for sales purposes
     *
     * Owner: Milad Amin
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.registrationFormSubmitted = function (properties, options) {
        return this.track(new RegistrationFormSubmitted(properties), options);
    };
    /**
     * Registration Form Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Registration%20Form%20Viewed)
     *
     * This event tracks when a user views the registration form for sales purposes
     *
     * Owner: Milad Amin
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.registrationFormViewed = function (properties, options) {
        return this.track(new RegistrationFormViewed(properties), options);
    };
    /**
     * Remember Me
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Remember%20Me)
     *
     * Remember me button for the [login](https://www.bloomberglaw.com/signin)
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.rememberMe = function (properties, options) {
        return this.track(new RememberMe(properties), options);
    };
    /**
     * Report Requested
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Report%20Requested)
     *
     * Fire when users successfully requested a report
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.reportRequested = function (properties, options) {
        return this.track(new ReportRequested(properties), options);
    };
    /**
     * Request Demo
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Request%20Demo)
     *
     * Request Demo from [Login Page]()
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.requestDemo = function (properties, options) {
        return this.track(new RequestDemo(properties), options);
    };
    /**
     * Results Drilldown Clicked
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Results%20Drilldown%20Clicked)
     *
     * Fire when users click an embedded link in the tool to drilldown on details
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.resultsDrilldownClicked = function (properties, options) {
        return this.track(new ResultsDrilldownClicked(properties), options);
    };
    /**
     * Saved for Later
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Saved%20for%20Later)
     *
     * Fires when a user saves content for later
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.savedForLater = function (properties, options) {
        return this.track(new SavedForLater(properties), options);
    };
    /**
     * Saved Searches Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Saved%20Searches%20Viewed)
     *
     * User views list of saved searches
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.savedSearchesViewed = function (properties, options) {
        return this.track(new SavedSearchesViewed(properties), options);
    };
    /**
     * Scroll Depth
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Scroll%20Depth)
     *
     * Fire when the user scrolls down any page at 25% increments.
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.scrollDepth = function (properties, options) {
        return this.track(new ScrollDepth(properties), options);
    };
    /**
     * Search Export Customized
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Export%20Customized)
     *
     * User customizes their search export
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.searchExportCustomized = function (properties, options) {
        return this.track(new SearchExportCustomized(properties), options);
    };
    /**
     * Search Form Closed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Form%20Closed)
     *
     * When search forms or modals are closed without submitting.
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.searchFormClosed = function (properties, options) {
        return this.track(new SearchFormClosed(properties), options);
    };
    /**
     * Search Form Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Form%20Viewed)
     *
     * Fire on any form or modal that serves as a search form or page devoted to initiating search (not search bars embedded in pages like the go bar) and include searchTool to name the specific search form.
     *
     * Owner: dylan.blass-svedvik@searchdiscovery.com
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.searchFormViewed = function (properties, options) {
        return this.track(new SearchFormViewed(properties), options);
    };
    /**
     * Search Result Hidden
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Result%20Hidden)
     *
     * User clicks to hide a search result - ideally includes document id
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.searchResultHidden = function (properties, options) {
        return this.track(new SearchResultHidden(properties), options);
    };
    /**
     * Search Results Clicked
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Results%20Clicked)
     *
     * User clicks through to a document from Search Results.
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. agency)
     * @param options Amplitude event options.
     */
    Ampli.prototype.searchResultsClicked = function (properties, options) {
        return this.track(new SearchResultsClicked(properties), options);
    };
    /**
     * Search Results Exported
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Results%20Exported)
     *
     * User exports/downloads Search Results or When the user tries to export content from a [dockets search result]()...
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.searchResultsExported = function (properties, options) {
        return this.track(new SearchResultsExported(properties), options);
    };
    /**
     * Search Results Navigated
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Results%20Navigated)
     *
     * Click on the Search Results CTA on a [document](https://www.bloomberglaw.com/product/blaw/document/X1JQ07JTG000N?criteria\_id=84c1018ec813139841de1aa2f1912b2d&navCriteriaId=3d6cce9c45c74c474f47647a70e68a3e&searchGuid=7f0cad2c-be82-4cc7-ac6c-3b50b2c78b6b&search32=H4g51LVe0iM0p08RRFKLsA%3D%3DRYD33FJ\_mhvHoY2LKNiKiiq3JRBTESO6h3zCMx9wZZkEdpkGaFUoYq7zp2az7ldtVf0oFM7QtuA0lE80TgXIcMJ84XGFh-nt\_GolpFoLz6L2qD2B0pd4AOWnfmQP4Ith).
     *
     * Owner: dylan.blass-svedvik@searchdiscovery.com
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.searchResultsNavigated = function (properties, options) {
        return this.track(new SearchResultsNavigated(properties), options);
    };
    /**
     * Search Results Sorted
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Results%20Sorted)
     *
     * User sorts their search from the search results page
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.searchResultsSorted = function (properties, options) {
        return this.track(new SearchResultsSorted(properties), options);
    };
    /**
     * Search Results Unhidden
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Results%20Unhidden)
     *
     * User clicks to unhide a search result - ideally includes document id
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.searchResultsUnhidden = function (properties, options) {
        return this.track(new SearchResultsUnhidden(properties), options);
    };
    /**
     * Search Results Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Results%20Viewed)
     *
     * Any load of search results on a search results page.
     *
     * Owner: dylan.blass-svedvik@searchdiscovery.com
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.searchResultsViewed = function (properties, options) {
        return this.track(new SearchResultsViewed(properties), options);
    };
    /**
     * Search Saved
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Saved)
     *
     * User saves search
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.searchSaved = function (properties, options) {
        return this.track(new SearchSaved(properties), options);
    };
    /**
     * Search Shared
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Shared)
     *
     * User clicks 'Share' (or email icon) above search results and copies the share link provided, or submits email recipients for the share. (use method)
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.searchShared = function (properties, options) {
        return this.track(new SearchShared(properties), options);
    };
    /**
     * Search Submitted
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Search%20Submitted)
     *
     * Fire when user submits a keyword search or selects/modifies filters or otherwise takes an action to load new search results.
     *
     * Owner: dylan.blass-svedvik@searchdiscovery.com
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.searchSubmitted = function (properties, options) {
        return this.track(new SearchSubmitted(properties), options);
    };
    /**
     * Selections Saved
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Selections%20Saved)
     *
     * Fire when the user has saved 1 or more sets of selections.
     *  Example: user selects a set of Jurisdictions in Chart Builder and then saves them for later use.
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.selectionsSaved = function (properties, options) {
        return this.track(new SelectionsSaved(properties), options);
    };
    /**
     * Set as Home
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Set%20as%20Home)
     *
     * User clicks set as home to make a feature or section page their home page
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.setAsHome = function (properties, options) {
        return this.track(new SetAsHome(properties), options);
    };
    /**
     * Sidebar Tab Open
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Sidebar%20Tab%20Open)
     *
     * The sidebar tab within a document
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.sidebarTabOpen = function (properties, options) {
        return this.track(new SidebarTabOpen(properties), options);
    };
    /**
     * Subscriber Added
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Subscriber%20Added)
     *
     * First login of a subscriber
     *
     * Owner: dylan.blass-svedvik@searchdiscovery.com
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.subscriberAdded = function (properties, options) {
        return this.track(new SubscriberAdded(properties), options);
    };
    /**
     * Table Results Formatted
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Table%20Results%20Formatted)
     *
     * Fire when users use table field options to update pivot table results, such as adding, removing, reordering, resetting fields or applying table limits
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.tableResultsFormatted = function (properties, options) {
        return this.track(new TableResultsFormatted(properties), options);
    };
    /**
     * TOC Exported
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/TOC%20Exported)
     *
     * Fires when a user exports a Table of Contents
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.tocExported = function (properties, options) {
        return this.track(new TocExported(properties), options);
    };
    /**
     * TOC Shared
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/TOC%20Shared)
     *
     * Fires when a user shares a Table of Contents
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.tocShared = function (properties, options) {
        return this.track(new TocShared(properties), options);
    };
    /**
     * TOC Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/TOC%20Viewed)
     *
     * Fires when a user views a Table of Contents
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.tocViewed = function (properties, options) {
        return this.track(new TocViewed(properties), options);
    };
    /**
     * Tour Ended
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Tour%20Ended)
     *
     * This event signifies the completion of the product onboarding tour, indicating that a user has finished the guided tour designed to familiarize them with the product's features and functionality
     *
     * Owner: Kyle Contrata
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.tourEnded = function (properties, options) {
        return this.track(new TourEnded(properties), options);
    };
    /**
     * Tour Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Tour%20Viewed)
     *
     * This event tracks when a user views a tour during the product onboarding process
     *
     * Owner: Kyle Contrata
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.tourViewed = function (properties, options) {
        return this.track(new TourViewed(properties), options);
    };
    /**
     * Track Alert Created
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Track%20Alert%20Created)
     *
     * This event tracks the creation of a new alert in the Alert Management system.  A Track Alert tracks changes to a document, such as a Bill.  Note that Dockets have their own specific event called Docket Track Created.
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. agency)
     * @param options Amplitude event options.
     */
    Ampli.prototype.trackAlertCreated = function (properties, options) {
        return this.track(new TrackAlertCreated(properties), options);
    };
    /**
     * Track Alert Deleted
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Track%20Alert%20Deleted)
     *
     * When a user deletes a tracked alert
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.trackAlertDeleted = function (properties, options) {
        return this.track(new TrackAlertDeleted(properties), options);
    };
    /**
     * Track Alert Modified
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Track%20Alert%20Modified)
     *
     * When a user modifies a track alert
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.trackAlertModified = function (properties, options) {
        return this.track(new TrackAlertModified(properties), options);
    };
    /**
     * Upsell Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Upsell%20Viewed)
     *
     * This event fires when a user views an upsell offer (page or modal), regardless of where it occurs within the application. The Upsell page or Modal will be shown to users trying to click/access a part of the application that they do not have access to.
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.upsellViewed = function (properties, options) {
        return this.track(new UpsellViewed(properties), options);
    };
    /**
     * User Workflow Exported
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/User%20Workflow%20Exported)
     *
     * This event fires when the user downloads/exports Workflow Content and clicks on Excel Export, Download, etc.
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.userWorkflowExported = function (properties, options) {
        return this.track(new UserWorkflowExported(properties), options);
    };
    /**
     * User Workflow Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/User%20Workflow%20Viewed)
     *
     * This event fires when visitors engage with User Workflow items such as Research Trai, Alert Management, and Download Center at the top of BLAW/BTAX and the top right corner of BGOV (after clicking the hamburger menu).
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.userWorkflowViewed = function (properties, options) {
        return this.track(new UserWorkflowViewed(properties), options);
    };
    /**
     * Workflow Viewed
     *
     * [View in Tracking Plan](https://data.amplitude.com/bindg/Global%20Product%20Workspace/events/main/latest/Workflow%20Viewed)
     *
     * This event tracks when a user views a workflow in the BTAX Software
     *
     * Owner: Philip Ramsey
     *
     * @param properties The event's properties (e.g. appName)
     * @param options Amplitude event options.
     */
    Ampli.prototype.workflowViewed = function (properties, options) {
        return this.track(new WorkflowViewed(properties), options);
    };
    return Ampli;
}());
exports.Ampli = Ampli;
exports.ampli = new Ampli();

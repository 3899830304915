"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
var ampli_1 = require("../ampli");
var ampliProps = {
    appName: 'Bloomberg Law Trial',
    appVersion: (_b = (_a = ampli_1.DefaultConfiguration.plan) === null || _a === void 0 ? void 0 : _a.version) !== null && _b !== void 0 ? _b : '1.0.0',
    brand: 'INDG',
    domain: 'bloomberglaw.com',
    httpStatusCode: 200,
    isLoggedIn: false,
    platform: 'ECDS',
    product: 'BLAW',
};
exports.default = ampliProps;

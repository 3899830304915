"use strict";
/**
 * @file routes.constants.ts
 * @description File containing the paths and routes for each page in the project
 */
Object.defineProperty(exports, "__esModule", { value: true });
var BASE = '/register';
var REG_NEWS = '/register/news';
var BLAW = 'blaw';
var BTAX = 'btax';
var BGOV = 'bgov';
var SUCCESSFUL = '/auth/trials/successful';
var SUCCESSFUL_NEWS = '/auth/trials/news/successful';
var UNSUCCESSFUL = '/auth/trials/unsuccessful';
var UNSUCCESSFUL_NEWS = '/auth/trials/news/unsuccessful';
var routes = {
    BASE: BASE,
    BLAW: BLAW,
    BTAX: BTAX,
    BGOV: BGOV,
    SUCCESSFUL: SUCCESSFUL,
    SUCCESSFUL_NEWS: SUCCESSFUL_NEWS,
    UNSUCCESSFUL: UNSUCCESSFUL,
    UNSUCCESSFUL_NEWS: UNSUCCESSFUL_NEWS,
    REG_NEWS: REG_NEWS,
};
exports.default = routes;

"use strict";
/**
 * @file titles.constants.ts
 * @description File containing the document titles each page in the project
 */
Object.defineProperty(exports, "__esModule", { value: true });
var titles = {
    HOME: "Bloomberg Law Trial",
    BLAW: "Bloomberg Law Registration Page",
    BTAX: "Bloomberg Tax Registration Page",
    BGOV: "Bloomberg Government Registration Page"
};
exports.default = titles;

"use strict";
/**
 * @file inputValidation.services.ts
 * @description File containing the input validation functions
 */
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * @function isRequired
 * @description Checks if the value passed is truthy and returns error if not
 * @param {string} value Value to check for truthiness
 * @returns {undefined|string}
 */
var isRequired = function (value) { return (value ? undefined : 'Required field'); };
/**
 * @function hasFormat
 * @description Checks if the value passed has the format specified and returns erros if not
 * @param {string} format Format to check. Can be email, phone, etc.
 * @param {string} value Value to check against format
 * @returns {undefined|string}
 */
var hasFormat = function (format) {
    return function (value) {
        var nameRegex = /^[a-zA-Z\s']*$/;
        var emailRegex = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[a-zA-Z0-9-]+/;
        var phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im; // eslint-disable-line
        var letterOnlyRegex = /^[a-zA-Z\s]*$/;
        switch (format) {
            case 'name':
                return nameRegex.test(value)
                    ? undefined
                    : 'Must contain only letters and some symbols';
            case 'letter-only':
                return letterOnlyRegex.test(value)
                    ? undefined
                    : 'Must contain only letters';
            case 'email':
                return emailRegex.test(value)
                    ? undefined
                    : 'Must be formatted as email';
            case 'phone':
                return phoneRegex.test(value)
                    ? undefined
                    : 'Must be formatted as phone';
            default:
                return 'Must provide a format to test';
        }
    };
};
/**
 * @function composeValidators
 * @description Checks if the value passed has the format specified and returns erros if not
 * @param {string} format Format to check. Can be email, phone, etc.
 * @param {string} value Value to check against format
 * @returns {undefined|string}
 */
var composeValidators = function () {
    var validators = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        validators[_i] = arguments[_i];
    }
    return function (value) {
        return validators.reduce(function (error, validator) { return error || validator(value); }, undefined);
    };
};
exports.default = {
    isRequired: isRequired,
    hasFormat: hasFormat,
    composeValidators: composeValidators,
};

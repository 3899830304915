"use strict";
/**
 * @file form.constants.ts
 * @description File containing constants for the different forms
 */
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * @name countryList
 * @type Object
 * @description Object containing the values and names for all countries
 */
var countryList = {
    // '0': 'Select One',
    US: 'United States',
    // AF: 'Afghanistan',
    // AX: 'Aland Islands',
    // AL: 'Albania',
    // DZ: 'Algeria',
    // AS: 'American Samoa',
    // AD: 'Andorra',
    // AO: 'Angola',
    // AI: 'Anguilla',
    // AQ: 'Antarctica',
    // AG: 'Antigua and Barbuda',
    // AR: 'Argentina',
    // AM: 'Armenia',
    // AW: 'Aruba',
    // AU: 'Australia',
    // AT: 'Austria',
    // AZ: 'Azerbaijan',
    // BS: 'Bahamas',
    // BH: 'Bahrain',
    // BD: 'Bangladesh',
    // BB: 'Barbados',
    // BY: 'Belarus',
    // BE: 'Belgium',
    // BZ: 'Belize',
    // BJ: 'Benin',
    // BM: 'Bermuda',
    // BT: 'Bhutan',
    // BO: 'Bolivia',
    // BQ: 'Bonaire, St. Eustatius, and Saba (BES)',
    // BA: 'Bosnia and Herzegovina',
    // BW: 'Botswana',
    // BR: 'Brazil',
    // IO: 'British Indian Ocean Territory',
    // BN: 'Brunei Darussalam',
    // BG: 'Bulgaria',
    // BF: 'Burkina Faso',
    // BI: 'Burundi',
    // KH: 'Cambodia',
    // CM: 'Cameroon',
    // CA: 'Canada',
    // CV: 'Cape Verde',
    // KY: 'Cayman Islands',
    // CF: 'Central African Republic',
    // TD: 'Chad',
    // CL: 'Chile',
    // CN: 'China',
    // CX: 'Christmas Island',
    // CC: 'Cocos Islands',
    // CO: 'Colombia',
    // KM: 'Comoros',
    // CG: 'Congo',
    // CK: 'Cook Islands',
    // CR: 'Costa Rica',
    // CI: "Cote d'Ivoire",
    // HR: 'Croatia',
    // CW: 'Curacao',
    // CY: 'Cyprus',
    // CZ: 'Czech Republic',
    // CD: 'Dem. Republic Of The Congo',
    // DK: 'Denmark',
    // DJ: 'Djibouti',
    // DM: 'Dominica',
    // DO: 'Dominican Republic',
    // EC: 'Ecuador',
    // EG: 'Egypt',
    // SV: 'El Salvador',
    // GQ: 'Equatorial Guinea',
    // ER: 'Eritrea',
    // EE: 'Estonia',
    // ET: 'Ethiopia',
    // FK: 'Falkland Islands (Malvinas)',
    // FO: 'Faroe Islands',
    // FJ: 'Fiji',
    // FI: 'Finland',
    // FR: 'France',
    // GF: 'French Guiana',
    // PF: 'French Polynesia',
    // GA: 'Gabon',
    // GM: 'Gambia',
    // GE: 'Georgia',
    // DE: 'Germany',
    // GH: 'Ghana',
    // GI: 'Gibraltar',
    // GR: 'Greece',
    // GL: 'Greenland',
    // GD: 'Grenada',
    // GP: 'Guadeloupe',
    // GU: 'Guam',
    // GT: 'Guatemala',
    // GG: 'Guernsey',
    // GN: 'Guinea',
    // GW: 'Guinea-Bissau',
    // GY: 'Guyana',
    // HT: 'Haiti',
    // HN: 'Honduras',
    // HK: 'Hong Kong SAR',
    // HU: 'Hungary',
    // IS: 'Iceland',
    // IN: 'India',
    // ID: 'Indonesia',
    // IQ: 'Iraq',
    // IE: 'Ireland',
    // IM: 'Isle Of Man',
    // IL: 'Israel',
    // IT: 'Italy',
    // JM: 'Jamaica',
    // JP: 'Japan',
    // JE: 'Jersey',
    // JO: 'Jordan',
    // KZ: 'Kazakhstan',
    // KE: 'Kenya',
    // KI: 'Kiribati',
    // KR: 'Korea, Republic of',
    // KW: 'Kuwait',
    // KG: 'Kyrgyzstan',
    // LA: "Lao People's Democratic Republic",
    // LV: 'Latvia',
    // LB: 'Lebanon',
    // LS: 'Lesotho',
    // LR: 'Liberia',
    // LY: 'Libyan Arab Jamahiriya',
    // LI: 'Liechtenstein, Principality of',
    // LT: 'Lithuania',
    // LU: 'Luxembourg',
    // MO: 'Macau SAR',
    // MK: 'Macedonia, Republic of',
    // MG: 'Madagascar',
    // MW: 'Malawi',
    // MY: 'Malaysia',
    // MV: 'Maldives',
    // ML: 'Mali',
    // MT: 'Malta',
    // MH: 'Marshall Islands',
    // MQ: 'Martinique',
    // MR: 'Mauritania',
    // MU: 'Mauritius',
    // YT: 'Mayotte',
    // MX: 'Mexico',
    // FM: 'Micronesia, Federated States of',
    // MD: 'Moldova, Republic of',
    // MC: 'Monaco',
    // MN: 'Mongolia',
    // ME: 'Montenegro',
    // MS: 'Montserrat',
    // MA: 'Morocco',
    // MZ: 'Mozambique',
    // MM: 'Myanmar',
    // NA: 'Namibia',
    // NR: 'Nauru, Republic of',
    // NP: 'Nepal',
    // AN: 'Netherlands Antilles',
    // NC: 'New Caledonia',
    // NZ: 'New Zealand',
    // NI: 'Nicaragua',
    // NE: 'Niger',
    // NG: 'Nigeria',
    // NU: 'Niue',
    // NF: 'Norfolk Island',
    // MP: 'Northern Mariana Islands',
    // NO: 'Norway',
    // PS: 'Occupied Palestinian Territory',
    // OM: 'Oman',
    // PK: 'Pakistan',
    // PW: 'Palau',
    // PA: 'Panama',
    // PG: 'Papua New Guinea',
    // PY: 'Paraguay',
    // PE: 'Peru',
    // PH: 'Philippines',
    // PN: 'Pitcairn',
    // PL: 'Poland',
    // PT: 'Portugal',
    // PR: 'Puerto Rico',
    // QA: 'Qatar',
    // RE: 'Reunion',
    // RO: 'Romania',
    // RU: 'Russian Federation',
    // RW: 'Rwanda',
    // SH: 'Saint Helena',
    // KN: 'Saint Kitts and Nevis',
    // LC: 'Saint Lucia',
    // MF: 'Saint Martin (French Part)',
    // PM: 'Saint Pierre and Miquelon',
    // VC: 'Saint Vincent and the Grenadines',
    // SM: 'San Marino',
    // ST: 'Sao Tome and Principe',
    // SA: 'Saudi Arabia',
    // SN: 'Senegal',
    // RS: 'Serbia',
    // SC: 'Seychelles',
    // SL: 'Sierra Leone',
    // SG: 'Singapore',
    // SX: 'Sint Maarten (Dutch Part)',
    // SK: 'Slovak Republic',
    // SI: 'Slovenia',
    // SB: 'Solomon Islands',
    // SO: 'Somalia',
    // ZA: 'South Africa',
    // GS: 'South Georgia And The South Sandwich Islands',
    // SS: 'South Sudan',
    // ES: 'Spain',
    // LK: 'Sri Lanka',
    // SD: 'Sudan',
    // SR: 'Suriname',
    // SJ: 'Svalbard and Jan Mayen',
    // SZ: 'Swaziland',
    // SE: 'Sweden',
    // CH: 'Switzerland',
    // TW: 'Taiwan (Republic of China)',
    // TJ: 'Tajikistan',
    // TZ: 'Tanzania, United Republic of',
    // TH: 'Thailand',
    // NL: 'The Netherlands',
    // TL: 'Timor-Leste',
    // TG: 'Togo',
    // TK: 'Tokelau',
    // TO: 'Tonga',
    // TT: 'Trinidad and Tobago',
    // TN: 'Tunisia',
    // TR: 'Turkey',
    // TM: 'Turkmenistan',
    // TC: 'Turks and Caicos Islands',
    // TV: 'Tuvalu',
    // UG: 'Uganda',
    // UA: 'Ukraine',
    // AE: 'United Arab Emirates',
    // GB: 'United Kingdom',
    // UM: 'United States Minor Outlying Islands',
    // UY: 'Uruguay',
    // UZ: 'Uzbekistan',
    // VU: 'Vanuatu',
    // VA: 'Vatican City State',
    // VE: 'Venezuela',
    // VN: 'Viet Nam, Socialist Republic of',
    // VG: 'Virgin Islands (British)',
    // VI: 'Virgin Islands (U.S.)',
    // WF: 'Wallis And Futuna Islands',
    // EH: 'Western Sahara',
    // WS: 'Western Samoa',
    // YE: 'Yemen',
    // ZM: 'Zambia',
    // ZW: 'Zimbabwe',
};
/**
 * @name usStateList
 * @type Object
 * @description Object containing the values and names for all US states
 */
var usStateList = {
    // '0': 'Select One',
    AL: 'ALABAMA',
    AK: 'ALASKA',
    AS: 'AMERICAN SAMOA',
    AZ: 'ARIZONA',
    AR: 'ARKANSAS',
    AE: 'ARMED FORCES EUROPE',
    AP: 'ARMED FORCES PACIFIC',
    AA: 'ARMED FORCES THE AMERICAS',
    CA: 'CALIFORNIA',
    CO: 'COLORADO',
    CT: 'CONNECTICUT',
    DE: 'DELAWARE',
    DC: 'DISTRICT OF COLUMBIA',
    FM: 'FEDERATED STATES OF MICRO',
    FL: 'FLORIDA',
    GA: 'GEORGIA',
    GU: 'GUAM',
    HI: 'HAWAII',
    ID: 'IDAHO',
    IL: 'ILLINOIS',
    IN: 'INDIANA',
    IA: 'IOWA',
    KS: 'KANSAS',
    KY: 'KENTUCKY',
    LA: 'LOUISIANA',
    ME: 'MAINE',
    MH: 'MARSHALL ISLANDS',
    MD: 'MARYLAND',
    MA: 'MASSACHUSETTS',
    MI: 'MICHIGAN',
    MN: 'MINNESOTA',
    MS: 'MISSISSIPPI',
    MO: 'MISSOURI',
    MT: 'MONTANA',
    NE: 'NEBRASKA',
    NV: 'NEVADA',
    NH: 'NEW HAMPSHIRE',
    NJ: 'NEW JERSEY',
    NM: 'NEW MEXICO',
    NY: 'NEW YORK',
    NC: 'NORTH CAROLINA',
    ND: 'NORTH DAKOTA',
    MP: 'NORTHERN MARIANA ISLANDS',
    OH: 'OHIO',
    OK: 'OKLAHOMA',
    OR: 'OREGON',
    PA: 'PENNSYLVANIA',
    PR: 'PUERTO RICO',
    RI: 'RHODE ISLAND',
    SC: 'SOUTH CAROLINA',
    SD: 'SOUTH DAKOTA',
    TN: 'TENNESSEE',
    TX: 'TEXAS',
    UT: 'UTAH',
    VT: 'VERMONT',
    VI: 'VIRGIN ISLANDS',
    VA: 'VIRGINIA',
    WA: 'WASHINGTON',
    WV: 'WEST VIRGINIA',
    WI: 'WISCONSIN',
    WY: 'WYOMING',
};
/**
 * @name usStateList
 * @type Object
 * @description Object containing the values and names for all CA states
 */
var caStateList = {
    // '0': 'Select One',
    AB: 'ALBERTA',
    BC: 'BRITISH COLUMBIA',
    MB: 'MANITOBA',
    NB: 'NEW BRUNSWICK',
    NL: 'NEWFOUNDLAND - LABRADOR',
    NT: 'NORTHWEST TERRITORY',
    NS: 'NOVA SCOTIA',
    NU: 'NUNAVUT',
    ON: 'ONTARIO',
    PE: 'PRINCE EDWARD ISLAND',
    QC: 'QUEBEC',
    SK: 'SASKATCHEWAN',
    YT: 'YUKON',
};
/**
 * @name usStateList
 * @type Object
 * @description Object containing the values and names for all CA states
 */
var orgList = {
    // '0': 'Select One',
    LAW: 'Law Firm',
    TAX: 'Tax / Accounting Firm',
    CORPORATION: 'Corporation',
    NONPROFIT: 'Non-Profit / Association',
    CONSULTING: 'Consulting Firm',
    EDUCATION: 'Education Institution',
    GOVERNMENT: 'Government Firm',
};
exports.default = {
    countryList: countryList,
    usStateList: usStateList,
    caStateList: caStateList,
    orgList: orgList,
};
